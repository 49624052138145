import React from 'react';
import { Box, Typography, Link as MuiLink } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function Footer() {
  const { t } = useTranslation();
  return (
    <Box
      component="footer"
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#000',
        color: '#fff',
        alignItems: 'center',
      }}
    >
      <MuiLink underline="hover" sx={{ color: '#fff', mx: 2 }}>
        <Typography variant="body2">Privacy Policy</Typography>
      </MuiLink>
      <MuiLink underline="hover" sx={{ color: '#fff', mx: 2 }}>
        <Typography variant="body2">Terms & Conditions</Typography>
      </MuiLink>

      <Link to="/about" style={{ textDecoration: 'none', mx: 2 }}>
        <Typography color="#fff" variant="h5" fontWeight="500" sx={{ ml: 1, my: 2 }}>
          {/* {t('About')} */}
          About
        </Typography>
      </Link>

      {/* <Link to="/downloads" style={{ textDecoration: 'none' }}> */}
      <Typography color="#fff" variant="h5" fontWeight="500" sx={{ ml: 1, my: 2, mx: 2 }}>
        {/* {t('downloads')} */}
        Downloads
      </Typography>
      {/* </Link> */}

      {/* <a href="/ctu-map" style={{ textDecoration: 'none' }}> */}
      <Typography color="#fff" variant="h5" fontWeight="500" sx={{ ml: 1, my: 2, mx: 2 }}>
        {/* {t('blogs')} */}
        Blogs
      </Typography>
      {/* </a> */}
    </Box>
  );
}
