import React from 'react';
import { Typography, Button, Box, TextField, Grid, Stack } from '@mui/material';
import AmbedkarImage from '../../assets/images/landing/ambedkar.png';
import Navbar from '../../layouts/full-layout/header/homeHeader';

export default function AmbedkarScreen() {
  const handleNavigate = () => {
    window.location.href = 'https://statuesque-biscochitos-01b3d8.netlify.app/';
  };

  return (
    <>
      <Navbar />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '20px',
          marginTop: "80px"
        }}
      >
        <Grid container spacing={2} sx={{ padding: '20px' }}>
          {/* Form Section */}
          <Grid item xs={12} md={6} sx={{ padding: '20px' }}>
            <Typography
              variant="h4"
              gutterBottom
              sx={{ textAlign: 'center', fontWeight: 'bold', color: '#7a5e34', fontSize: '30px' }}
            >
              Ask Baba Sahib
            </Typography>
            <Typography
              variant="body2"
              sx={{ textAlign: 'center', color: '#646464', mb: 2, fontSize: '18px' }}
            >
              Enter your details below to continue
            </Typography>
            <Typography
              variant="caption"
              display="block"
              sx={{ textAlign: 'center', color: '#82672D', marginBottom: '20px', fontSize: '15px' }}
            >
              *This information would be used to give you a personalized experience.
            </Typography>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginBottom: '16px',
              }}
            >
              {/* Responsive TextField Width */}
              <TextField
                placeholder="Enter Name"
                variant="outlined"
                sx={{
                  width: { xs: '100%', sm: '80%', md: '450px' },
                  marginBottom: '16px',
                  '& .MuiInputBase-root': {
                    borderRadius: '6px',
                  },
                  '& .MuiInputBase-input': {
                    textAlign: 'center',
                  },
                  backgroundColor: '#E4D9C4',
                }}
              />
              <TextField
                placeholder="Enter Age"
                variant="outlined"
                sx={{
                  width: { xs: '100%', sm: '80%', md: '450px' },
                  marginBottom: '16px',
                  '& .MuiInputBase-root': {
                    borderRadius: '6px',
                  },
                  '& .MuiInputBase-input': {
                    textAlign: 'center',
                  },
                  backgroundColor: '#E4D9C4',
                }}
              />
            </Box>

            <Typography
              variant="body1"
              sx={{ textAlign: 'center', marginBottom: '8px', color: '#7a5e34' }}
            >
              Select Gender
            </Typography>
            <Stack
              direction="row"
              spacing={2}
              justifyContent="center"
              sx={{ marginBottom: '16px' }}
            >
              <Button
                sx={{
                  width: { xs: '100px', sm: '120px', md: '140px' },
                  borderRadius: '10px',
                  color: '#646464',
                  backgroundColor: '#E4D9C4',
                  border: '1px solid #5A3E00',
                  '&:hover': {
                    backgroundColor: '#d1c2a6',
                  },
                }}
              >
                Male
              </Button>
              <Button
                sx={{
                  width: { xs: '100px', sm: '120px', md: '140px' },
                  borderRadius: '10px',
                  color: '#646464',
                  backgroundColor: '#E4D9C4',
                  border: '1px solid #5A3E00',
                  '&:hover': {
                    backgroundColor: '#d1c2a6',
                  },
                }}
              >
                Female
              </Button>
              <Button
                sx={{
                  width: { xs: '100px', sm: '120px', md: '140px' },
                  borderRadius: '10px',
                  color: '#646464',
                  backgroundColor: '#E4D9C4',
                  border: '1px solid #5A3E00',
                  '&:hover': {
                    backgroundColor: '#d1c2a6',
                  },
                }}
              >
                Others
              </Button>
            </Stack>

            <Box display="flex" justifyContent="center">
              <Button
                variant="contained"
                color="primary"
                sx={{
                  marginRight: '18px',
                  width: { xs: '180px', sm: '200px', md: '220px' },
                  backgroundColor: 'black',
                  color: 'white',
                  '&:hover': {
                    backgroundColor: '#333333',
                  },
                }}
                onClick={handleNavigate}
              >
                Next
              </Button>

              {/* <Button
              variant="outlined"
              color="primary"
              sx={{
                width: { xs: '80px', sm: '100px' },
                borderColor: '#5A3E00', 
                color: '#5A3E00', 
                '&:hover': {
                  backgroundColor: '#f1e7d6',
                },
              }}
            >
              Skip
            </Button> */}
            </Box>
          </Grid>

          {/* Image Section */}
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: { xs: '20px', md: '0' },
            }}
          >
            <Box
              component="img"
              src={AmbedkarImage}
              alt="Dr. B.R. Ambedkar"
              sx={{
                maxWidth: { xs: '80%', md: '60%' },
                borderRadius: '10px',
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
