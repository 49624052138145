import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  Menu,
  MenuItem,
  IconButton,
  Drawer,
  List,
} from '@mui/material';
import PropTypes from 'prop-types';
import MenuIcon from '@mui/icons-material/Menu';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import headerLogo from '../../../assets/logos/headerLogo.png';
import languageLogo from '../../../assets/logos/languageLogo.png';
import ambedkarIconImage from '../../../assets/images/landing/ambedkarIconImage.png';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const LogoIcon = () => {
  return (
    <Link underline="none" to="/">
      <img src={headerLogo} style={{ height: '50px' }} alt="LOGO" />
    </Link>
  );
};

const Header = ({ sx }) => {
  const { t, i18n } = useTranslation();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [loginAnchorEl, setLoginAnchorEl] = useState(null);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    dispatch(updateRole({ info: {} }));
    dispatch(updateProfile({ logged_in: false, info: {}, token: '' }));
    firebase.auth().signOut();
  };

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const languages = [
    { code: 'en', label: 'English' },
    { code: 'hi', label: 'हिन्दी' },
  ];

  const handleLoginClick = (event) => {
    setLoginAnchorEl(event.currentTarget);
  };

  const handleLoginClose = () => {
    setLoginAnchorEl(null);
  };

  const loginOptions = [
    { label: t('State/District/Block/Nodal Officer'), link: '/auth/login' },
    { label: t('Institution/Central Govt/State Govt Dept'), link: '/auth/login' },
    { label: t('Panchayat/Urban Area Organiser'), link: '/auth/login' },
  ];

  const menuItems = (
    <>
      <Link to="/" style={{ textDecoration: 'none' }}>
        <Typography color="#DDC489" variant="h5" fontWeight="500" sx={{ ml: 1, my: 2 }}>
          {t('home')}
        </Typography>
      </Link>

      <Link to="/constitution" style={{ textDecoration: 'none' }}>
        <Typography color="#DDC489" variant="h5" fontWeight="500" sx={{ ml: 1, my: 2 }}>
          {t('constitution')}
        </Typography>
      </Link>
      <Link to="/preambles" style={{ textDecoration: 'none' }}>
        <Typography color="#DDC489" variant="h5" fontWeight="500" sx={{ ml: 1, my: 2 }}>
          {t('preamble')}
        </Typography>
      </Link>
      <Link
        to="/ask-baba-sahib"
        style={{ textDecoration: 'none', display: 'flex', alignItems: 'center' }}
      >
        <Typography color="#DDC489" variant="h5" fontWeight="500">
          {t('ask_baba')}
        </Typography>
        <img
          src={ambedkarIconImage}
          alt="Ambedkar Icon"
          style={{ height: '30px', width: '30px' }}
        />
      </Link>

      <Link to="/making-of-constitution" style={{ textDecoration: 'none' }}>
        <Typography color="#DDC489" variant="h5" fontWeight="500">
          {t('making_of_constitution')}
        </Typography>
      </Link>

      <>
        <Typography
          color="#DDC489"
          variant="h5"
          fontWeight="500"
          sx={{ ml: 1, my: 2, cursor: 'pointer' }}
          onClick={handleLoginClick}
        >
          {t('login')}
          <ArrowDropDownIcon sx={{ ml: 0.5 }} />
        </Typography>
        <Menu anchorEl={loginAnchorEl} open={Boolean(loginAnchorEl)} onClose={handleLoginClose}>
          {loginOptions.map((option) => (
            <MenuItem
              key={option.label}
              component={Link}
              to={option.link}
              onClick={handleLoginClose}
            >
              {option.label}
            </MenuItem>
          ))}
        </Menu>
      </>
      <div className="lang_div">
        <IconButton onClick={handleClick}>
          <img src={languageLogo} alt="Language Icon" style={{ width: 24, height: 24 }} />
        </IconButton>
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
          {languages.map((lang) => (
            <MenuItem
              key={lang.code}
              onClick={() => {
                changeLanguage(lang.code);
                handleClose();
              }}
            >
              {lang.label}
            </MenuItem>
          ))}
        </Menu>
      </div>
    </>
  );

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          ...sx,
          backgroundColor: '#000000',
          boxShadow: '0px 4px 33.8px 0px #00000033',
          zIndex: 1300,
        }}
        elevation={0}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={toggleSidebar}
            sx={{ mr: 2, display: { xs: 'block', md: 'none' } }} // HAMBURGER MENU FOR MOBILE
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: "100%" }}>
            <LogoIcon />
            <Box
              sx={{
                display: { xs: 'none', md: 'flex' }, // MENU ITEMS ONLY VISIBLE ON DESKTOP
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '60%',
              }}
            >
              {menuItems}
            </Box>
          </Box>
        </Toolbar>
      </AppBar>

      {/* SIDEBAR DRAWER FOR MOBILE VIEW */}
      <Drawer anchor="left" open={sidebarOpen} onClose={toggleSidebar}>
        <Box
          sx={{ width: 250, m: 2 }}
          role="presentation"
          onClick={toggleSidebar}
          onKeyDown={toggleSidebar}
        >
          <List>{menuItems}</List>
        </Box>
      </Drawer>
    </>
  );
};

Header.propTypes = {
  sx: PropTypes.object,
};

export default Header;