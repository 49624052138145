import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import Loadable from '../layouts/full-layout/loadable/Loadable';
import { element } from 'prop-types';
import Home from '../views/home';
// import HomeAfter from '../views/home';

/* ***Layouts**** */
const FullLayout = Loadable(lazy(() => import('../layouts/full-layout/FullLayout')));
const BlankLayout = Loadable(lazy(() => import('../layouts/blank-layout/BlankLayout')));
const Preamble = Loadable(lazy(() => import('../views/preambles')));
const Constitution = Loadable(lazy(() => import('../views/constitution')));
const PreambleForm = Loadable(lazy(() => import('../views/publicParticipation')));
const About = Loadable(lazy(() => import('../views/about')));
const AskBaba = Loadable(lazy(() => import('../components/ambedkarPage')));
const MakingConstitution = Loadable(lazy(() => import('../views/makingConstitution')));
const Authentication = Loadable(lazy(() => import('../views/authentication')));
const Register = Loadable(lazy(() => import('../views/authentication/Register')));
const Login = Loadable(lazy(() => import('../views/authentication/Login')));
const Dashboard = Loadable(lazy(() => import('../views/dashboard')));

/* ***End Layouts**** */


/* ****Pages***** */
const PageContainer = Loadable(lazy(() => import('../components/pageContainer/pageContainer')));

/* ****Routes***** */

const routes = [
  {
    path: '/',
    element: (
      <PageContainer title="Home">
        <Home />
      </PageContainer>
    ),
  },
  // {
  //   path: 'ask-baba-sahib',
  //   element: (
  //     <PageContainer title="AskBaba">
  //       <AskBaba />
  //     </PageContainer>
  //   ),
  // },

  // {
  //   path: 'preambles',
  //   element: (
  //     <PageContainer title="Preambles">
  //       <Preamble />
  //     </PageContainer>
  //   ),
  // },
  // {
  //   path: 'constitution',
  //   element: (
  //     <PageContainer title="Constitution">
  //       <Constitution />
  //     </PageContainer>
  //   ),
  // },
  // {
  //   path: 'making-of-constitution',
  //   element: (
  //     <PageContainer title="Making of Constitution">
  //       <MakingConstitution />
  //     </PageContainer>
  //   ),
  // },
  // {
  //   path: 'public-participation',
  //   element: (
  //     <PageContainer title="Public Participation">
  //       <PreambleForm />
  //     </PageContainer>
  //   ),
  // },
  // {
  //   path: 'about',
  //   element: (
  //     <PageContainer title="About Us">
  //       <About />
  //     </PageContainer>
  //   ),
  // },
  // {
  //   path: 'preambles',
  //   element: (
  //     <PageContainer title="Preambles List">
  //       <Preamble />
  //     </PageContainer>
  //   ),
  // },

  // {
  //   path: 'auth',
  //   element: <Authentication />,
  //   children: [
  //     { path: '', element: <Navigate to="register" /> },
  //     { path: 'register', element: <Register /> },
  //     { path: 'login', element: <Login /> },
  //   ],
  // },

  // {
  //   path: '/',
  //   element: <FullLayout />,
  //   children: [
  //     { path: 'dashboard', element: <Dashboard /> }
  //   ],
  // },
  // {
  //   path: 'auth',
  //   element: <BlankLayout />,
  //   children: [
  //     { path: '404', element: <Error /> },
  //     { path: 'login', element: <Login /> },
  //     { path: '*', element: <Navigate to="/auth/404" /> },
  //   ],
  // },
];

export default routes;
