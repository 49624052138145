import React from 'react';
import { Box, useTheme, useMediaQuery, Typography } from '@mui/material';
import Navbar from '../../layouts/full-layout/header/homeHeader';
import { useNavigate } from 'react-router';
import LandingBanner from '../../components/carousel/LandingBanner';
import AmbedkarScreen from '../../components/ambedkarPage';
import MapScreen from '../../components/mapScreen/mapScreen';
import MediaGallery from '../../components/mediaGallery/mediaGallery';
import SocialMedia from '../../components/socialMedia/socialMedia';
import Footer from '../../components/footer/footer';
import BgImage from '../../assets/images/landing/bgImage.png';
import headerImage from '../../assets/images/landing/headerImage.jpg';

const Home = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    // <div style={{ width: '100%', minHeight: '100vh', backgroundColor: '#FFF' }}>
    //   {/* <Navbar /> */}
    //   <Box
    //     sx={{
    //       display: 'flex',
    //       alignItems: 'center',
    //       justifyContent: 'center',
    //       flexDirection: 'column',
    //       overflowX: 'hidden',
    //       position: 'relative',
    //       paddingTop: isSmallScreen ? '56px' : '70px',
    //     }}
    //   >
    //     {/* <LandingBanner /> */}

    //     {/* Container Box for Header and Background Images */}
    //     <Box
    //       sx={{
    //         width: '100%',
    //         position: 'relative',
    //         overflow: 'hidden',
    //       }}
    //     >
    //       {/* Header Image */}
    //       {/* <Box
    //         sx={{
    //           width: '100%',
    //           height: { xs: '80px' },
    //           backgroundImage: `url(${headerImage})`,
    //           backgroundSize: 'cover',
    //           backgroundPosition: 'center',
    //         }}
    //       /> */}

    //       {/* Section with BgImage */}
    //       {/* <Box
    //         sx={{
    //           width: '100%',
    //           backgroundImage: `url(${BgImage})`,
    //           backgroundSize: 'contain',
    //           backgroundPosition: 'center',
    //           paddingTop: '40px',
    //         }}
    //       >
    //         <AmbedkarScreen />
    //         <MapScreen />
    //         <MediaGallery />
    //         <SocialMedia />
    //       </Box> */}
    //     </Box>

    //     <Footer />
    //   </Box>
    // </div>
    <Box
      textAlign="center"
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      height="100vh"
      sx={{
        backgroundImage: `url(${BgImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <Typography variant="h1" fontSize="48px" fontWeight="bold" sx={{ color: '#B99744' }}>
        Coming Soon
      </Typography>
    </Box>
  );
};

export default Home;
